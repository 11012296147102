// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contactme-tsx": () => import("./../../../src/pages/contactme.tsx" /* webpackChunkName: "component---src-pages-contactme-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-articles-tsx": () => import("./../../../src/pages/my-articles.tsx" /* webpackChunkName: "component---src-pages-my-articles-tsx" */),
  "component---src-pages-my-projects-tsx": () => import("./../../../src/pages/my-projects.tsx" /* webpackChunkName: "component---src-pages-my-projects-tsx" */),
  "component---src-templates-article-description-js": () => import("./../../../src/templates/article-description.js" /* webpackChunkName: "component---src-templates-article-description-js" */),
  "component---src-templates-project-description-js": () => import("./../../../src/templates/project-description.js" /* webpackChunkName: "component---src-templates-project-description-js" */)
}

